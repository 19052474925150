import React from 'react';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import BASE_URL from '../../auth/baseURL';

export default function ControlledSwitches({ className, scope, value }) {
    const [checked, setChecked] = React.useState(value);

    const handleChange = async (event) => {
        const newChecked = event.target.checked;
        setChecked(newChecked);

        try {
            await axios.post(
                `${BASE_URL}/users/${localStorage.getItem('userId')}/preferences`,
                {
                    scope: scope,
                    value: newChecked 
                },
                {
                    headers: {
                        'yomi_token': localStorage.getItem('yomi_token'),
                    },
                }
            );
        } catch (err) {
            console.error("Error updating preference", err);
        }
    };

    return (
        <Switch
            className={className}
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}