// OperatorPopup.jsx
import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../../auth/baseURL';
import './popup.scss';
import Auth from "../../auth/Auth";

const headers = {
  yomi_token: `${localStorage.getItem('yomi_token')}`
}

const OperatorPopup = ({ onClose, onOperatorAdded }) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const userId = Auth.getUserId();

  const handleSave = async () => {
    if (!name || !role) {
      setErrorMsg("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/users/${userId}/add/operator`,
        {
          name,
          role,
        },
        {headers}
      );
      onOperatorAdded(response.data); // Adjusted based on the response data
      onClose();
    } catch (error) {
      console.error(
        "Error adding operator:",
        error.response?.data?.msg || error.message
      );
      setErrorMsg("Error adding operator.");
    }
  };

  return (
    <div className="popup">
      <div className="popupContent">
        <h2>Create New Operator</h2>
        {errorMsg && <p className="error">{errorMsg}</p>}
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter operator name"
          />
        </label>
        <label>
          Role:
          <input
            type="text"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            placeholder="Enter operator role"
          />
        </label>
        <div className="buttons">
          <button onClick={handleSave} className="saveButton">
            Save
          </button>
          <button onClick={onClose} className="closeButton">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OperatorPopup;
