import React from 'react';
import './tags.scss';
import Sidebar from '../../components/Sidebar/Sidebar';

const Tags = () => {
  return (
    <div className='tags'>
        <Sidebar />
        <div className="homeContainer">
            Tags
        </div>
    </div>
  );
};

export default Tags;