import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import './customTable.scss';
import Auth from '../../auth/Auth';
import BASE_URL from '../../auth/baseURL';
import Loading from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import Notification from '../../components/Notification/Notification';
import jsPDF from 'jspdf';
import NunitoFont from '../../fonts/nunito.js';

const userId = Auth.getUserId();

const headers = {
  yomi_token: `${localStorage.getItem('yomi_token')}`,
};

const CustomTable = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  const fetchRecords = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/records/${userId}`, {
        headers,
      });
      const data = response.data;

      if (Array.isArray(data)) {
        setRecords(data);
      } else if (Array.isArray(data.records)) {
        setRecords(data.records);
      } else {
        setRecords([]);
        console.error('API response is not an array:', data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setRecords([]);
      setLoading(false);
    }
  };

  const deleteRecord = async (recordId) => {
    try {
      await axios.delete(`${BASE_URL}/records/${userId}/${recordId}`, {
        headers,
      });
      setRecords((prevRecords) =>
        prevRecords.filter((record) => record._id !== recordId)
      );
      setNotificationTitle('Record Deleted');
      setNotificationMessage(
        `Record "${recordId}" has been deleted successfully.`
      );
      setNotificationType('success');
      setNotificationOpen(true);
    } catch (error) {
      console.error('Error deleting record', error);
      setNotificationTitle('Error Deleting Record');
      setNotificationMessage(
        `An error occurred while deleting record "${recordId}".`
      );
      setNotificationType('error');
      setNotificationOpen(true);
    }
  };

  const copyLink = (recordId) => {
    const link = `${window.location.origin}/${userId}/${recordId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setNotificationTitle('Link Copied');
        setNotificationMessage(
          `Link to record "${recordId}" has been copied to clipboard.`
        );
        setNotificationType('success');
        setNotificationOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy the link:', err);
        setNotificationTitle('Copy Error');
        setNotificationMessage('Failed to copy the link.');
        setNotificationType('error');
        setNotificationOpen(true);
      });
  };

  const downloadPDF = (record) => {
    const doc = new jsPDF();
    doc.addFileToVFS('Nunito.ttf', NunitoFont);
    doc.addFont('Nunito.ttf', 'Nunito', 'normal');
    doc.setFont('Nunito');
    doc.setFontSize(18);
    doc.text(`File Name: ${record.fileName}`, 20, 20);
    doc.setFontSize(14);
    doc.text('Operator:', 20, 40);
    doc.setFontSize(12);
    doc.text(record.operator || 'No operator', 50, 40);
    doc.setFontSize(14);
    doc.text('Script:', 20, 50);
    doc.setFontSize(12);
    doc.text(record.script || 'No script', 50, 50);
    doc.setFontSize(16);
    doc.setFont('Nunito', 'bold');
    doc.text('Analysis:', 20, 70);
    doc.setFont('Nunito', 'normal');
    doc.setFontSize(12);
    doc.text(record.details?.analysis || 'No analysis available', 20, 80, {
      maxWidth: 170,
    });
    doc.addPage();
    doc.setFont('Nunito', 'bold');
    doc.setFontSize(16);
    doc.text('Transcript:', 20, 20);
    doc.setFont('Nunito', 'normal');
    doc.setFontSize(12);
    doc.text(record.details?.transcript || 'No transcript available', 20, 30, {
      maxWidth: 170,
    });
    doc.addPage();
    doc.setFont('Nunito', 'bold');
    doc.setFontSize(16);
    doc.text('Client Profile:', 20, 20);
    doc.setFont('Nunito', 'normal');
    doc.setFontSize(12);
    doc.text(record.details?.profile || 'No client profile available', 20, 30, {
      maxWidth: 170,
    });
    doc.save(`Record_${record._id}.pdf`);
  };

  const handleSSEUpdates = (updatedRecord) => {
    const { _id } = updatedRecord;
    setRecords((prevRecords) => {
      const recordIndex = prevRecords.findIndex((record) => record._id === _id);
      if (recordIndex !== -1) {
        const updatedRecords = [...prevRecords];
        updatedRecords[recordIndex] = {
          ...updatedRecords[recordIndex],
          ...updatedRecord,
        };
        setNotificationTitle('Record Updated');
        setNotificationMessage(
          `"${updatedRecords[recordIndex].fileName}" has been updated.`
        );
        setNotificationType('success');
        setNotificationOpen(true);
        return updatedRecords;
      } else {
        return prevRecords;
      }
    });
  };

  useEffect(() => {
    fetchRecords();
    const eventSource = new EventSource(
      `${BASE_URL}/records/${userId}/updates/live?yomi_token=${localStorage.getItem(
        'yomi_token'
      )}`
    );
    eventSource.onmessage = (event) => {
      const updatedRecord = JSON.parse(event.data);
      handleSSEUpdates(updatedRecord);
    };
    eventSource.onerror = (error) => {
      console.error('SSE connection error:', error);
      eventSource.close();
    };
    return () => {
      eventSource.close();
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (record) => {
    return record.details && Object.keys(record.details).length > 0
      ? 'green'
      : 'yellow';
  };

  const displayedRows = Array.isArray(records)
    ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: 'rgb(23, 23, 23)',
              border: '1px solid rgb(50, 50, 50)',
              borderRadius: '5px',
            }}
          >
            <Table aria-label="basic table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '25%', color: 'white' }}>
                    File name
                  </TableCell>
                  <TableCell
                    sx={{ width: '10%', color: 'white', textAlign: 'center' }}
                  >
                    Operator
                  </TableCell>
                  <TableCell
                    sx={{ width: '10%', color: 'white', textAlign: 'center' }}
                  >
                    Script
                  </TableCell>
                  <TableCell
                    sx={{ width: '10%', color: 'white', textAlign: 'center' }}
                  >
                    Date Added
                  </TableCell>
                  <TableCell
                    sx={{ width: '10%', color: 'white', textAlign: 'center' }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ width: '25%', color: 'white', textAlign: 'center' }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedRows.length > 0 ? (
                  displayedRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                        <Link
                          to={`${userId}/${row._id}`}
                          className="blue"
                          style={{ textDecoration: 'none' }}
                        >
                          {row.fileName}
                        </Link>
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          borderBottom: 'none',
                          textAlign: 'center',
                        }}
                      >
                        {row.operator}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          borderBottom: 'none',
                          textAlign: 'center',
                        }}
                      >
                        {row.script}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          borderBottom: 'none',
                          textAlign: 'center',
                        }}
                      >
                        {new Date(row.dateAdded).toLocaleDateString()}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          borderBottom: 'none',
                          textAlign: 'center',
                        }}
                      >
                        <CircleRoundedIcon
                          className={`status ${getStatusColor(row)}`}
                        />
                      </TableCell>
                      <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                        <div className="actions">
                          <DownloadRoundedIcon
                            className="icon download"
                            onClick={() => downloadPDF(row)}
                            style={{ cursor: 'pointer' }}
                          />
                          <IosShareRoundedIcon
                            className="icon share"
                            onClick={() => copyLink(row._id)}
                            style={{ cursor: 'pointer' }}
                          />
                          <DeleteOutlineRoundedIcon
                            className="icon bin"
                            onClick={() => deleteRecord(row._id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      align="center"
                      sx={{ color: 'white' }}
                    >
                      No records found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={records.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            sx={{ color: 'white' }}
          />
          <Notification
            title={notificationTitle}
            message={notificationMessage}
            open={notificationOpen}
            onClose={() => setNotificationOpen(false)}
            type={notificationType}
          />
        </>
      )}
    </Box>
  );
};

export default CustomTable;
