import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './singleRecord.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import axios from 'axios';
import Loading from '../../components/Loading/Loading.jsx';
import BASE_URL from '../../auth/baseURL.js';

const headers = {
  yomi_token: `${localStorage.getItem('yomi_token')}`,
};

const SingleRecord = () => {
  const { recordId, userId } = useParams();
  const [analysis, setAnalysis] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [profile, setProfile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [operator, setOperator] = useState('');
  const [script, setScript] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BASE_URL}/records/${userId}/${recordId}`,
          { headers }
        );

        const record = response.data;
        setAnalysis(
          record.details?.analysis !== '' ? record.details.analysis : null
        );
        setTranscript(
          record.details?.transcript !== '' ? record.details.transcript : null
        );
        setProfile(
          record.details?.profile !== '' ? record.details.profile : null
        );
        setFileName(record.fileName || 'No file name');
        setOperator(record.operator || 'No operator');
        setScript(record.script || 'No script');
      } catch (error) {
        console.error('Error fetching record details:', error);
        setError('Failed to load the record details.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecordDetails();
  }, [recordId, userId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="singleRecord">
      <Sidebar />
      <div className="homeContainer">
        <div className="content">
          <span className="ID">Record ID: {recordId}</span>
          <div className="fileInfo">
            <p>
              <strong>File Name:</strong> {fileName}
            </p>
            <p>
              <strong>Operator:</strong> {operator}
            </p>
            <p>
              <strong>Script:</strong> {script}
            </p>
          </div>
          <div className="blocks">
            <div className="block">
              <span className="title">Analysis:</span>
              <span className="text">{analysis || 'No analysis available.'}</span>
            </div>
            <div className="block">
              <span className="title">Transcript:</span>
              <span className="text">
                {transcript || 'No transcript available.'}
              </span>
            </div>
            <div className="block">
              <span className="title">Client Profile:</span>
              <span className="text">
                {profile || 'No client profile available.'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRecord;
