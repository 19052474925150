import React from "react";
import "./dashboard.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import HelmetComponent from "../../components/Helmet/Helmet";

const Dashboard = () => {
    return (
        <div className="dashboard">
            <HelmetComponent title="Dashboard"/>
            <Sidebar />
            <div className="homeContainer">
                <div className="top">
                    <div className="left">
                        <div className="row">
                            <span className="cell"></span>
                            <span className="cell"></span>
                        </div>
                        <div className="row">
                            <span className="cell"></span>
                            <span className="cell"></span>
                        </div>
                    </div>
                    <div className="right">
                        <span className="1">top right</span>
                    </div>
                </div>
                <div className="bottom">
                    <div className="left">
                        <span className="1">bottom left</span>
                    </div>
                    <div className="right">
                        <span className="1">bottom right</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;