import React, { useState, useEffect } from "react";
import axios from "axios";
import "./profile.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import HelmetComponent from "../../components/Helmet/Helmet";
import BASE_URL from "../../auth/baseURL";
import Loading from "../../components/Loading/Loading";

const Profile = () => {
  const [user, setUser] = useState(null); // State to store user data
  const [error, setError] = useState(null); // State to handle errors
  const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
  const yomiToken = localStorage.getItem('yomi_token'); // Retrieve yomi_token from localStorage

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/${userId}/get/profile`, {
          headers: { yomi_token: yomiToken },
        });
        setUser(response.data); // Set the user data in state
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError("Failed to load profile data.");
      }
    };

    fetchUserProfile();
  }, [userId, yomiToken]); // Dependencies for the effect

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="profile">
      <HelmetComponent title="Profile" />
      <Sidebar />
      <div className="homeContainer">
        <h1>User Profile</h1>
        {user ? (
          <>
            <h1>User ID: {userId}</h1>
            <h1>Organization: {user.organization}</h1>
            <h1>Name: {user.name}</h1>
            <h1>Phone: {user.phone}</h1>
            <h1>Email: {user.email}</h1>
            <h1>Country: {user.country}</h1>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Profile;
