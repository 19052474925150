// ScriptPopup.jsx
import React, { useState } from "react";
import axios from "axios";
import Auth from "../../auth/Auth";
import BASE_URL from "../../auth/baseURL";
import "./popup.scss";

const headers = {
  yomi_token: `${localStorage.getItem('yomi_token')}`
}

const ScriptPopup = ({ onClose, onScriptAdded }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const userId = Auth.getUserId();

  const handleSave = async () => {
    if (!title || !content) {
      setErrorMsg("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/users/${userId}/add/script`,
        {
          title,
          content,
        },
        {headers}
      );
      onScriptAdded(response.data); // Adjusted based on the response data
      onClose();
    } catch (error) {
      console.error(
        "Error adding script:",
        error.response?.data?.msg || error.message
      );
      setErrorMsg("Error adding script.");
    }
  };

  return (
    <div className="popup">
      <div className="popupContent">
        <h2>Upload New Script</h2>
        {errorMsg && <p className="error">{errorMsg}</p>}
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter script title"
          />
        </label>
        <label>
          Content:
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter script content"
          ></textarea>
        </label>
        <div className="buttons">
          <button onClick={handleSave} className="saveButton">
            Save
          </button>
          <button onClick={onClose} className="closeButton">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScriptPopup;
