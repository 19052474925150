// Settings.jsx
import React, { useState, useEffect } from "react";
import "./settings.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Auth from "../../auth/Auth";
import axios from "axios";
import BASE_URL from "../../auth/baseURL";
import Loading from "../../components/Loading/Loading";
import Notification from "../../components/Notification/Notification";
import HelmetComponent from "../../components/Helmet/Helmet";
import OperatorPopup from "../../components/Popups/OperatorPopup";
import ScriptPopup from "../../components/Popups/ScriptPopup";
import { Link } from 'react-router-dom';
import ControlledSwitches from "../../components/Switch/Switch";


const Settings = () => {
const [showScriptPopup, setShowScriptPopup] = useState(false);
const [showOperatorPopup, setShowOperatorPopup] = useState(false);
const [niche, setNiche] = useState("");
const [analysis_instruction, setAnalysis] = useState("");
const [clientProfile, setClientProfile] = useState("");
const [scripts, setScripts] = useState([]);
const [operators, setOperators] = useState([]);
const [loading, setLoading] = useState(true);
const [notificationOpen, setNotificationOpen] = useState(false);
const [notificationMessage, setNotificationMessage] = useState("");
const [transcription_status, setTranscriptionStatus] = useState(false);
const [analysis_status, setAnalysisStatus] = useState(false);
const [client_profile_status, setClientProfileStatus] = useState(false);

const userId = Auth.getUserId();

const headers = {
    yomi_token: `${localStorage.getItem('yomi_token')}`
}

useEffect(() => {
    const fetchSettingsInfo = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/users/${userId}/get/settings`, {headers});
        const settings = response.data;


        setNiche(settings.preferences.niche);
        setClientProfile(settings.preferences.clientProfile);
        setAnalysis(settings.preferences.analysis);
        setScripts(settings.scripts || []);
        setOperators(settings.operators || []);

        setTranscriptionStatus(settings.preferences.functions.transcription);
        setAnalysisStatus(settings.preferences.functions.analysis);
        setClientProfileStatus(settings.preferences.functions.client_profile);
        console.log(transcription_status);
        console.log(analysis_status);
        console.log(client_profile_status);
    } catch (error) {
        console.error(
        "Error getting settings info",
        error.response?.data?.msg || error.message
        );
        setNotificationMessage(
        "Error getting settings info: " +
            (error.response?.data?.msg || error.message)
        );
        setNotificationOpen(true);
    } finally {
        setLoading(false);
    }
    };

    fetchSettingsInfo();
}, [userId]);

const handleSave = async () => {
    try {
    const response = await axios.put(`${BASE_URL}/users/${userId}/update/settings`, {niche, clientProfile, analysis_instruction}, {headers});
    console.log("Settings updated:", response.data);
    setNotificationMessage("Settings saved successfully!");
    setNotificationOpen(true);
    } catch (error) {
    console.error(
        "Error updating settings:",
        error.response?.data?.msg || error.message
    );
    setNotificationMessage(
        "Error updating settings: " +
        (error.response?.data?.msg || error.message)
    );
    setNotificationOpen(true);
    }
};

const handleScriptAdded = (newScript) => {
    setScripts((prevScripts) => [...prevScripts, newScript]);
};

const handleOperatorAdded = (newOperator) => {
    setOperators((prevOperators) => [...prevOperators, newOperator]);
};

const handleNotificationClose = () => {
    setNotificationOpen(false);
};

if (loading) {
    return (
    <div className="settings">
        <Sidebar />
        <div className="homeContainer">
        <span className="section">Settings</span>
        <Loading />
        </div>
    </div>
    );
}

return (
    <div className="settings">
        <HelmetComponent title="Settings" />
        <Sidebar />
        <div className="homeContainer">
            <span className="section">Settings</span>
            <div className="content">
                <div className="scripts">
                    <div className="header">
                        <span className="title">Scripts</span>
                        <span className="button" onClick={() => setShowScriptPopup(true)} >Upload new</span>
                    </div>
                    <div className="body">
                        {scripts.map((script) => (
                        <Link key={script._id} to={`/settings/${script._id}`} className="link" >{script.title}</Link>
                        ))}
                    </div>
                </div>
                <div className="operators">
                    <div className="header">
                        <span className="title">Operators</span>
                        <span className="button" onClick={() => setShowOperatorPopup(true)}>
                            Create new
                        </span>
                    </div>
                    <div className="body">
                        {operators.map((operator, index) => (
                            <span key={index} className="link">
                            {operator.name}{" "}
                            <span className="role">{operator.role}</span>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="preferences">
                    <div className="header">
                        <span className="title">Preferences</span>
                    </div>
                    <div className="body">
                        <div className="options">
                            <span className="subtitle">Functions</span>
                            <div className="option">
                                <span className="option_title">Transcription</span>
                                <ControlledSwitches className='switch' scope='transcription' value={transcription_status}/>
                            </div>
                            <div className="option">
                                <div className="option">
                                    <span className="option_title">Analysis</span>
                                    <ControlledSwitches className='switch' scope='analysis' value={analysis_status}/>
                                </div>
                            </div>
                            <div className="option">
                                <div className="option">
                                    <span className="option_title">Client Profile</span>
                                    <ControlledSwitches className='switch' scope='client_profile' value={client_profile_status}/>
                                </div>
                            </div>
                        </div>
                        <div className="options">
                            <span className="subtitle two">Transcription models</span>
                            <div className="option">
                                <span className="option_title">asjlnkd</span>
                                <ControlledSwitches className='switch'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info">
                    <div className="header">
                        <span className="title">Info</span>
                        <span className="button" onClick={handleSave}>
                            Save
                        </span>
                        </div>
                        <div className="body">
                            <span className="what">Niche</span>
                            <textarea
                                className="input"
                                value={niche}
                                onChange={(e) => setNiche(e.target.value)}
                            />

                            <span className="what">Analysis instructions</span>
                            <textarea 
                                className="input"
                                value={analysis_instruction}
                                onChange={(e) => setAnalysis(e.target.value)}
                            />

                            <span className="what second">
                                Client profile ~ what are the most important details for making
                                up client profile?
                            </span>
                            <textarea
                                className="input"
                                value={clientProfile}
                                onChange={(e) => setClientProfile(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {showScriptPopup && (
                <ScriptPopup
                onClose={() => setShowScriptPopup(false)}
                onScriptAdded={handleScriptAdded}
                />
            )}
            {showOperatorPopup && (
                <OperatorPopup
                onClose={() => setShowOperatorPopup(false)}
                onOperatorAdded={handleOperatorAdded}
                />
            )}

            <Notification
                open={notificationOpen}
                onClose={handleNotificationClose}
                title={notificationMessage.includes("Error") ? "Error" : "Success"}
                message={notificationMessage}
                type={notificationMessage.includes("Error") ? "error" : "success"}
            />
        </div>
    );
};

export default Settings;
